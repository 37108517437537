export const USER = {
  USER_SIGNIN_START: 'USER_SIGNIN_START',
  USER_SIGNIN: 'USER_SIGNIN',
  USER_SIGNIN_ERROR: 'USER_SIGNIN_ERROR',
  USER_SIGNOUT: 'USER_SIGNOUT',
  USER_AUTH_START: 'USER_AUTH_START',
};

export const GLOBAL = {
  SET_LOADING: 'SET_LOADING',
};

//   export const CANDIDATE = {
//     GET_CANDIDATES: 'GET_CANDIDATES',
//     SET_SHOW_PICKED: 'SET_SHOW_PICKED',
//     SET_DETAILS: 'SET_DETAILS',
//     PICK_CANDIDATE: 'PICK_CANDIDATE',
//     START_FILTER_CANDIDATES: 'START_FILTER_CANDIDATES',
//     FILTER_CANDIDATES: 'FILTER_CANDIDATES',
//   };
