import React from 'react';

export const mountain = () => (
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="500.000000pt" height="500.000000pt" viewBox="0 0 400.000000 400.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M2825 3081 c-22 -4 -52 -15 -67 -25 -37 -25 -80 -83 -87 -120 -6 -25
-12 -32 -36 -34 -45 -4 -85 -22 -96 -43 -13 -26 5 -25 38 1 28 22 68 26 78 9
4 -5 14 -9 23 -7 13 2 16 12 14 38 -6 75 78 160 159 160 41 0 103 -42 119 -79
12 -30 10 -86 -5 -123 -6 -14 -2 -18 19 -18 16 0 42 -14 66 -36 22 -20 43 -34
47 -31 11 12 -29 59 -64 73 -33 14 -34 16 -28 54 10 57 -10 104 -58 144 -50
40 -69 45 -122 37z"/>
<path d="M2402 3043 c-6 -21 -20 -68 -31 -106 -12 -37 -21 -72 -21 -78 0 -17
-69 -170 -94 -209 -135 -208 -128 -199 -243 -319 -46 -47 -83 -87 -83 -88 0
-2 8 -3 19 -3 10 0 53 35 95 78 52 54 98 90 146 115 39 20 73 37 76 37 2 0 15
-25 29 -55 13 -30 29 -55 34 -55 15 0 35 34 67 112 l27 67 41 -61 c23 -34 47
-64 53 -66 7 -2 20 13 30 32 9 20 19 36 22 36 3 0 32 -16 64 -35 32 -19 62
-35 67 -35 4 0 27 -24 51 -52 24 -29 54 -65 68 -79 14 -14 50 -51 79 -83 49
-52 77 -66 77 -39 0 6 -31 44 -70 85 -204 215 -313 357 -367 478 -6 14 -24 53
-39 87 -16 33 -34 87 -39 120 -17 97 -32 153 -39 153 -4 0 -13 -17 -19 -37z
m104 -339 c24 -50 69 -124 99 -164 30 -41 55 -75 55 -77 0 -8 -57 27 -69 41
-21 25 -39 19 -59 -20 l-19 -36 -34 58 c-19 33 -42 60 -51 62 -11 2 -27 -21
-54 -76 l-39 -80 -22 34 c-13 18 -23 42 -23 53 0 31 -31 26 -65 -9 -61 -63
-44 -6 27 94 15 21 28 43 28 47 0 4 13 27 29 51 15 24 46 91 67 149 l39 106
23 -71 c12 -39 43 -112 68 -162z"/>
<path d="M1860 2878 c-28 -19 -45 -41 -66 -86 -1 -2 -21 2 -44 8 -34 9 -51 8
-90 -5 -56 -19 -89 -60 -97 -124 -5 -37 -8 -39 -47 -45 -52 -8 -103 -50 -116
-94 -12 -45 2 -49 145 -45 66 2 177 4 247 6 117 2 128 4 151 25 50 47 37 130
-23 151 -44 15 -65 14 -80 -4 -17 -20 -7 -29 18 -16 30 16 78 -4 90 -38 11
-31 -6 -74 -35 -86 -10 -4 -88 -9 -173 -12 -85 -2 -168 -5 -185 -6 -16 0 -54
0 -83 1 l-53 2 22 40 c26 45 49 55 110 47 40 -5 40 -5 39 26 -4 71 0 87 34
120 42 42 94 55 138 33 44 -22 47 -21 68 28 14 31 29 47 52 57 76 31 203 -7
222 -66 13 -42 22 -45 64 -23 26 14 49 18 77 14 38 -6 39 -5 20 10 -19 15 -55
15 -110 0 -15 -5 -25 2 -40 27 -27 42 -85 70 -160 75 -51 3 -65 0 -95 -20z"/>
<path d="M3175 2808 c-4 -17 -18 -41 -32 -54 -29 -26 -23 -50 8 -34 34 19 23
-29 -16 -66 -35 -33 -33 -54 4 -34 27 14 30 13 24 -11 -3 -12 -21 -30 -39 -41
-43 -25 -43 -31 1 -26 19 2 35 1 35 -3 0 -4 -17 -23 -37 -43 -31 -30 -34 -36
-17 -36 11 0 30 7 42 16 13 9 26 13 29 10 13 -13 -23 -70 -59 -94 -33 -22 -37
-27 -23 -35 12 -7 25 -3 46 12 15 12 31 21 34 21 2 0 3 -26 1 -57 -2 -32 -4
-79 -5 -104 l-1 -46 -72 -18 c-64 -15 -108 -36 -233 -110 -22 -13 -76 -39
-120 -56 -72 -30 -90 -33 -187 -33 -58 -1 -109 -4 -113 -8 -13 -13 67 -21 159
-15 95 5 173 31 276 90 179 103 231 122 333 119 87 -2 239 -48 262 -79 8 -10
17 -15 21 -11 29 30 -166 118 -261 118 l-26 0 2 105 c1 58 3 105 4 105 2 0 23
-9 49 -21 62 -27 83 -13 24 17 -32 16 -49 33 -56 54 -13 36 -13 36 33 9 34
-21 65 -20 65 1 0 5 -10 10 -23 10 -13 0 -33 10 -44 23 -40 44 -61 106 -24 73
10 -9 36 -19 57 -22 l39 -5 -32 18 c-34 19 -73 64 -73 83 0 9 8 7 25 -4 25
-17 55 -21 55 -8 0 4 -16 17 -36 30 -36 23 -59 65 -49 89 4 12 9 12 27 -1 24
-17 58 -21 58 -8 0 4 -13 15 -28 24 -16 9 -37 33 -47 52 -25 45 -51 47 -60 4z"/>
<path d="M3376 2690 c25 -10 60 -55 66 -85 2 -14 10 -19 23 -16 75 14 115 9
152 -20 20 -16 45 -44 55 -60 l19 -31 -143 2 c-173 3 -213 0 -133 -10 92 -12
305 -12 305 1 0 21 -30 67 -64 97 -43 37 -104 56 -157 47 -31 -5 -39 -4 -39 8
0 22 -61 77 -84 76 -21 0 -21 0 0 -9z"/>
<path d="M2882 2635 c-21 -9 -38 -27 -47 -49 -21 -49 -19 -71 8 -102 22 -25
32 -28 118 -34 94 -7 136 -2 89 11 -14 4 -55 7 -91 8 -51 1 -70 5 -87 21 -49
46 -9 130 62 130 19 0 38 -4 41 -10 8 -13 25 -13 25 0 0 11 -53 40 -72 40 -7
-1 -28 -7 -46 -15z"/>
<path d="M2646 2611 c-4 -6 -6 -11 -4 -11 53 -8 151 -9 143 -2 -16 15 -132 26
-139 13z"/>
<path d="M2025 2600 c-34 -9 -27 -11 67 -16 37 -2 70 1 73 6 4 6 -4 10 -17 10
-13 0 -39 2 -58 4 -19 3 -48 1 -65 -4z"/>
<path d="M2433 2375 c0 -44 2 -61 4 -37 2 23 2 59 0 80 -2 20 -4 1 -4 -43z"/>
<path d="M2596 2405 c8 -45 60 -149 110 -219 46 -67 74 -96 74 -78 0 6 -4 13
-9 17 -31 19 -131 188 -175 295 -3 8 -4 2 0 -15z"/>
<path d="M2215 2393 c-3 -15 -15 -39 -26 -54 -23 -33 -25 -46 -3 -28 9 8 25
33 35 57 22 50 22 52 10 52 -5 0 -12 -12 -16 -27z"/>
<path d="M2551 2370 c0 -36 52 -173 68 -178 11 -4 11 0 0 19 -7 13 -25 60 -40
104 -16 44 -28 69 -28 55z"/>
<path d="M2661 2386 c-2 -26 134 -202 144 -187 3 5 0 13 -7 18 -12 7 -98 117
-125 159 -6 11 -12 15 -12 10z"/>
<path d="M2496 2330 c7 -69 24 -150 36 -170 7 -12 8 -10 4 5 -3 11 -10 56 -16
100 -15 98 -32 144 -24 65z"/>
<path d="M2115 2331 c-16 -21 -42 -45 -57 -54 -15 -9 -28 -21 -28 -28 0 -14
77 48 104 84 11 15 17 29 15 32 -3 2 -18 -13 -34 -34z"/>
<path d="M2262 2330 c-7 -20 -28 -59 -47 -89 -19 -29 -29 -51 -22 -49 19 7 89
134 85 155 -3 13 -7 8 -16 -17z"/>
<path d="M2376 2313 c-4 -21 -9 -51 -12 -68 -4 -20 -3 -27 4 -20 10 10 27 125
19 125 -3 0 -8 -17 -11 -37z"/>
<path d="M2312 2281 c-7 -21 -12 -44 -12 -50 0 -22 17 -2 23 26 3 16 9 36 12
46 4 9 3 17 -2 17 -6 0 -15 -17 -21 -39z"/>
<path d="M2132 2245 c-24 -28 -27 -35 -12 -35 11 0 45 48 38 55 -2 3 -14 -6
-26 -20z"/>
<path d="M2442 2205 c-1 -22 3 -58 7 -80 l9 -40 0 35 c0 19 -3 55 -7 80 l-8
45 -1 -40z"/>
<path d="M1690 2212 c-14 -4 -25 -14 -25 -22 0 -11 7 -14 25 -10 14 3 72 5
130 5 131 -1 214 -21 340 -83 77 -38 113 -65 195 -142 163 -154 268 -199 420
-177 66 9 116 29 103 42 -3 4 -35 -2 -71 -11 -59 -16 -73 -16 -134 -4 -119 23
-170 53 -288 165 -113 107 -204 165 -336 211 -62 22 -96 27 -204 30 -71 2
-141 0 -155 -4z"/>
<path d="M2270 2171 c-12 -24 -13 -34 -1 -27 9 6 22 46 15 46 -2 0 -8 -9 -14
-19z"/>
<path d="M2357 2160 c-3 -8 -8 -33 -11 -55 l-6 -40 16 40 c8 22 13 46 11 55
-3 13 -5 13 -10 0z"/>
<path d="M2852 2135 c15 -16 30 -26 33 -23 4 3 -9 16 -27 28 l-33 23 27 -28z"/>
<path d="M2654 2105 c13 -27 33 -59 45 -69 31 -28 26 -11 -11 39 -18 23 -39
51 -46 61 -7 11 -1 -3 12 -31z"/>
<path d="M1738 2113 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1845 2114 c138 -16 294 -62 336 -100 18 -16 33 -19 24 -4 -25 41
-249 111 -343 109 -29 -1 -35 -3 -17 -5z"/>
<path d="M3279 2099 c29 -5 76 -21 104 -34 57 -29 74 -27 25 3 -42 25 -103 42
-148 41 -29 -1 -25 -3 19 -10z"/>
<path d="M2541 2087 c-1 -22 29 -77 42 -77 5 0 0 15 -11 33 -11 17 -22 39 -25
47 -4 11 -6 11 -6 -3z"/>
<path d="M3015 2069 c-36 -19 -85 -57 -60 -47 56 23 136 68 120 68 -11 0 -38
-9 -60 -21z"/>
<path d="M2460 2061 c0 -11 4 -22 9 -25 4 -3 6 6 3 19 -5 30 -12 33 -12 6z"/>
<path d="M1785 2040 l-50 -7 45 -1 c25 -1 52 3 60 8 8 5 11 9 5 8 -5 -1 -32
-4 -60 -8z"/>
<path d="M1871 2046 c2 -2 27 -6 54 -10 34 -4 43 -3 30 3 -19 9 -93 15 -84 7z"/>
<path d="M3101 2039 c-45 -11 -171 -85 -171 -101 0 -13 8 -10 58 26 92 64 109
70 212 70 52 1 109 -2 125 -7 26 -6 28 -6 10 5 -26 16 -177 20 -234 7z"/>
<path d="M1980 2026 c0 -2 11 -6 25 -8 13 -3 22 -1 19 3 -5 9 -44 13 -44 5z"/>
<path d="M2080 2001 c0 -4 21 -16 46 -26 28 -10 78 -47 127 -93 45 -41 101
-93 124 -114 44 -40 99 -69 130 -67 10 0 2 5 -17 11 -45 12 -74 35 -185 142
-58 56 -114 99 -157 121 -38 20 -68 31 -68 26z"/>
<path d="M2834 1961 c-77 -42 -149 -62 -225 -62 -46 0 -67 -4 -63 -11 8 -13
133 -4 184 13 43 14 160 71 166 82 9 13 -10 6 -62 -22z"/>
<path d="M3100 1971 c-63 -20 -170 -85 -170 -103 0 -6 10 -2 23 9 57 54 232
114 287 100 11 -3 18 -1 14 4 -9 14 -94 9 -154 -10z"/>
<path d="M1786 1961 c-5 -8 124 -3 133 5 2 2 -25 4 -62 4 -36 0 -68 -4 -71 -9z"/>
<path d="M1980 1961 c0 -6 7 -11 15 -11 28 0 115 -50 178 -103 116 -97 155
-127 167 -127 7 0 -10 18 -37 39 -26 22 -79 65 -117 96 -89 72 -206 133 -206
106z"/>
<path d="M2341 1886 c71 -66 170 -133 217 -145 59 -16 121 -23 97 -12 -11 5
-31 10 -45 10 -56 2 -215 103 -284 180 -11 11 -24 21 -30 21 -6 -1 14 -24 45
-54z"/>
<path d="M1850 1891 c0 -5 9 -7 19 -4 60 16 221 -49 297 -119 24 -23 47 -39
49 -36 8 8 -95 93 -142 116 -22 12 -61 28 -85 37 -47 16 -138 20 -138 6z"/>
<path d="M2780 1875 c-36 -13 -55 -24 -43 -24 22 -1 113 34 113 44 0 3 -1 4
-2 4 -2 -1 -32 -12 -68 -24z"/>
<path d="M2678 1843 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M1910 1820 c0 -5 9 -10 21 -10 11 0 29 -5 41 -11 11 -6 22 -8 25 -6
6 7 -24 22 -59 31 -17 4 -28 2 -28 -4z"/>
<path d="M2055 1768 c11 -6 27 -16 35 -22 9 -6 5 -1 -8 12 -13 12 -28 22 -35
22 -7 0 -3 -6 8 -12z"/>
</g>
</svg>
)

export const searchIcon = () => (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="110.000000pt" height="110.000000pt" viewBox="0 0 178.000000 178.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,178.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M743 1765 c-193 -35 -345 -115 -483 -254 -295 -297 -341 -751 -113
-1106 25 -38 82 -106 127 -150 289 -281 722 -333 1071 -127 93 55 232 188 290
277 160 245 188 566 73 832 -49 113 -100 187 -198 283 -205 203 -492 295 -767
245z m362 -54 c169 -45 354 -165 453 -297 67 -88 126 -207 154 -309 30 -113
30 -317 0 -430 -47 -173 -152 -336 -286 -442 -154 -121 -316 -182 -506 -188
-250 -9 -468 81 -642 263 -130 136 -205 294 -228 477 -55 438 236 844 670 936
111 23 273 19 385 -10z"/>
<path d="M871 1426 c-55 -58 -195 -109 -357 -129 l-99 -13 0 -241 0 -241 49
-96 c46 -92 66 -111 66 -62 0 57 92 180 165 222 18 10 17 13 -13 50 -64 81
-66 185 -3 264 45 56 95 80 172 80 59 0 72 -4 115 -32 56 -39 94 -103 94 -160
0 -58 -29 -48 -45 15 -23 91 -80 137 -171 137 -96 0 -164 -71 -164 -170 0 -65
25 -112 77 -145 41 -26 42 -51 4 -60 -32 -7 -105 -56 -133 -89 -29 -35 -57
-101 -63 -153 -7 -47 -6 -50 40 -101 26 -29 76 -73 110 -97 138 -99 222 -99
361 0 55 40 144 130 166 169 5 9 -15 31 -57 64 l-64 52 -36 -21 c-67 -40 -157
-16 -209 55 -31 41 -29 139 3 185 27 38 90 70 137 71 51 0 115 -40 141 -90 27
-50 29 -87 9 -135 l-15 -34 61 -51 c70 -59 65 -61 121 57 l32 68 0 245 0 245
-70 8 c-120 12 -262 65 -371 137 l-31 20 -22 -24z m110 -70 c92 -48 214 -90
297 -101 l53 -7 -3 -232 c-3 -230 -3 -231 -31 -283 -15 -29 -31 -53 -35 -53
-4 0 -19 12 -35 25 -25 23 -27 27 -18 67 18 80 -23 175 -91 212 -33 18 -34 21
-24 47 7 21 7 41 -2 76 -51 196 -294 259 -428 111 -49 -54 -64 -95 -64 -171 0
-49 6 -75 26 -112 l25 -48 -47 -46 c-25 -25 -59 -68 -74 -94 l-27 -48 -24 53
c-23 50 -24 66 -27 275 l-3 222 48 6 c154 20 307 70 368 120 17 13 31 24 31
25 1 0 39 -20 85 -44z m-74 -185 c46 -21 67 -53 77 -117 l7 -42 -37 -6 c-25
-5 -51 -22 -83 -55 l-47 -49 -40 27 c-85 58 -97 160 -26 224 41 38 92 44 149
18z m-92 -388 c10 -52 28 -85 69 -121 61 -54 116 -60 221 -27 14 4 32 -4 58
-25 l37 -32 -36 -44 c-42 -51 -142 -129 -192 -150 -42 -17 -111 -18 -149 -2
-47 20 -147 94 -187 138 -35 39 -38 46 -32 86 11 84 62 154 141 193 55 28 62
26 70 -16z"/>
<path d="M965 924 c-46 -23 -65 -53 -65 -106 0 -57 33 -102 89 -119 95 -28
184 81 138 171 -16 32 -73 70 -106 70 -14 0 -39 -7 -56 -16z m110 -49 c52 -51
16 -145 -55 -145 -37 0 -90 47 -90 80 0 78 91 119 145 65z"/>
</g>
</svg>
)

export const searchQuestion = () => (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="240.000000pt" height="417.000000pt" viewBox="0 0 412.000000 417.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,417.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M1475 4159 c-359 -36 -714 -202 -968 -452 -152 -150 -238 -268 -332
-454 -53 -107 -116 -291 -140 -413 -23 -117 -31 -378 -15 -504 34 -276 141
-540 315 -776 116 -158 305 -330 450 -411 33 -18 62 -36 65 -39 15 -17 225
-105 323 -134 152 -46 294 -66 468 -66 155 0 238 9 364 40 87 21 240 71 271
88 10 6 42 21 69 33 28 12 90 46 138 75 49 30 93 52 98 48 5 -3 89 -96 186
-207 97 -111 184 -209 192 -218 52 -54 229 -254 479 -540 197 -225 190 -219
260 -219 80 0 229 26 258 45 13 8 51 49 83 91 55 69 60 80 68 142 4 37 8 107
8 155 l0 88 -60 57 c-131 123 -224 209 -276 257 -29 27 -92 86 -139 130 -252
236 -365 342 -380 355 -9 8 -75 69 -145 135 -70 66 -132 123 -138 126 -6 4 -1
20 14 40 28 40 56 89 102 179 132 258 195 579 168 862 -30 324 -165 659 -359
891 -52 64 -228 234 -282 273 -341 251 -741 364 -1145 323z m360 -229 c203
-29 407 -108 585 -225 108 -71 265 -215 322 -295 18 -25 40 -53 48 -62 44 -48
143 -246 182 -363 51 -155 63 -240 63 -450 0 -174 -3 -214 -23 -305 -114 -508
-515 -926 -1009 -1051 -259 -65 -486 -65 -738 1 -391 103 -726 384 -899 755
-330 706 -31 1544 670 1875 126 59 234 92 379 115 98 16 325 18 420 5z m1155
-2851 c135 -138 255 -263 268 -277 13 -15 101 -106 195 -202 377 -384 378
-384 335 -435 -16 -18 -33 -26 -57 -27 -37 -1 -42 4 -176 156 -32 37 -68 78
-80 90 -23 26 -35 40 -195 221 -63 72 -129 146 -146 165 -17 19 -107 121 -200
225 -92 105 -183 206 -201 225 -18 19 -36 43 -39 53 -6 19 21 55 41 56 6 1
121 -112 255 -250z"/>
<path d="M1490 3853 c-331 -43 -628 -199 -845 -444 -47 -52 -115 -145 -142
-192 -65 -115 -133 -276 -146 -348 -3 -13 -13 -60 -22 -104 -20 -94 -24 -327
-6 -420 34 -184 85 -332 157 -455 67 -115 173 -248 260 -325 246 -219 518
-331 833 -342 315 -12 567 63 828 245 64 44 197 169 254 237 127 153 233 385
275 600 24 122 24 348 0 470 -67 343 -267 651 -551 849 -144 101 -324 176
-500 212 -79 15 -327 27 -395 17z m403 -114 c493 -100 887 -519 958 -1017 17
-121 7 -354 -20 -472 -64 -277 -258 -563 -486 -715 -33 -22 -62 -43 -65 -46
-12 -13 -171 -88 -240 -112 -131 -46 -231 -61 -400 -62 -162 0 -234 10 -373
51 -219 65 -438 214 -589 401 -182 226 -268 476 -268 778 0 253 67 473 207
682 203 302 543 502 913 537 84 8 265 -5 363 -25z"/>
<path d="M2029 3146 c-2 -3 -178 -8 -389 -12 -435 -7 -435 -7 -469 -78 -24
-48 -35 -189 -20 -247 17 -67 84 -116 144 -105 47 9 105 75 105 120 0 19 4 37
8 40 10 6 521 11 526 5 2 -2 6 -38 10 -79 l6 -75 -153 -74 c-224 -110 -254
-129 -277 -176 -27 -59 -39 -265 -16 -300 35 -54 120 -73 171 -39 38 25 52 59
60 146 l7 78 77 36 c164 78 317 157 335 173 44 39 46 53 27 277 -10 116 -24
221 -31 237 -17 36 -105 89 -121 73z"/>
<path d="M1583 1961 c-63 -29 -91 -98 -62 -154 46 -89 140 -101 204 -25 27 32
31 70 15 114 -12 29 -58 74 -77 74 -6 0 -17 2 -25 5 -7 2 -32 -4 -55 -14z"/>
</g>
</svg>
)

export const MenuIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48" height="48"><path d="M8.75 5.5h11.5a.75.75 0 0 1 0 1.5H8.75a.75.75 0 0 1 0-1.5Zm0 6h11.5a.75.75 0 0 1 0 1.5H8.75a.75.75 0 0 1 0-1.5Zm0 6h11.5a.75.75 0 0 1 0 1.5H8.75a.75.75 0 0 1 0-1.5ZM5 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM4 7a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm0 12a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"></path></svg>
)